import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Markers,
  Marker
} from "react-simple-maps";

const EventMap = props => {
  const wrapperStyles = {
    width: "98vw",
    height: "83.5vh",
    margin: "auto"
  };

  const [eventData, setEventData] = useState([]);
  const [zoomData, setZoomData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("listEvents");
      const eventRes = result.data.data;
      let events = eventRes.map(event => {
        if(!event.city){
          return{
            markerOffset: 10,
            name: 'Virtual Event',
            coordinates: [ "-87.6718", "41.8650"]
          }
          
        }
        return {
                    markerOffset: 10,
                    name: event.city.name,
                    coordinates: [event.city.lon, event.city.lat]
                  };
      });
      console.log(events);
      setEventData(events);
      setZoomData(".6");
    };
    fetchData();
  }, []);

  console.log(eventData);

  const markers = eventData;

  return (
    <div style={wrapperStyles}>
      <label>Zoom</label>
      <input
        type="range"
        className="custom-range"
        id="zoomControl"
        min="0.25"
        max="3"
        step=".05"
        value={zoomData}
        onChange={e => setZoomData(e.target.value)}
      ></input>
      <ComposableMap
        projectionConfig={{ scale: 1400 }}
        width={1024}
        height={800}
        style={{
          width: "100%",
          height: "96%"
        }}
      >
        <ZoomableGroup center={[-52, 37]} zoom={zoomData}>
          <Geographies geography="world-states-50m.json">
            {(geographies, projection) =>
              geographies.map((geography, i) => (
                // include.indexOf(geography.id) !== -1 && (

                <Geography
                  key={i}
                  geography={geography}
                  projection={projection}
                  style={{
                    default: {
                      fill: "#ECEFF1",
                      stroke: "#607D8B",
                      strokeWidth: 0.75,
                      outline: "none"
                    },

                    hover: {
                      fill: "#CFD8DC",
                      stroke: "#607D8B",
                      strokeWidth: 0.75,
                      outline: "none"
                    },

                    pressed: {
                      fill: "#FF5722",
                      stroke: "#607D8B",
                      strokeWidth: 0.75,
                      outline: "none"
                    }
                  }}
                />
              ))
            }
          </Geographies>

          <Markers>
            {markers.map((marker, i) => (
              <Marker
                key={i}
                marker={marker}
                style={{
                  default: { fill: "#FF5722" },
                  hover: { fill: "#FFFFFF" },
                  pressed: { fill: "#FF5722" }
                }}
              >
                <circle
                  cx={0}
                  cy={0}
                  r={5}
                  style={{
                    stroke: "#FF5722",
                    strokeWidth: 2,
                    opacity: 0.9
                  }}
                />

                <text
                  textAnchor="right"
                  x={marker.markerOffset}
                  y={5}
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "bold",
                    fill: "#607D8B",
                    fontSize: zoomData + "em"
                  }}
                >
                  {marker.name}
                </text>
              </Marker>
            ))}
          </Markers>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};

export default EventMap;
