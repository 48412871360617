import React from "react";

const Footer = () => {
    return (
			<div className="foot-wrap">
				<div></div>
        <div className="App-footer"><p>Copyright CDM Media USA INC. 2019</p></div>
			</div>
    );
};

export default Footer;