import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import LoginForm from './LoginForm';
import UserProfile from './UserProfile';
import Events from "./Events";
import Brands from "./Brands";
import EventCalendar from "./Calendar";
import EventMap from "./Map";
import Venues from "./Venues";
import './App.css';
import { saveState, loadState } from './localStorage';
import Logout from "./Logout";
import URL from './config'
import axios from 'axios';

const App = () => {

	const localState = loadState();

	const initState = localState ? localState : {
		email: '',
		user: '',
		cdmApiToken: '',
		message: "Please enter credentials for access"
	}

	if(initState.cdmApiToken){
		axios.defaults.headers.common['Authorization'] = "Bearer " + initState.cdmApiToken;
		axios.defaults.baseURL = URL.API;
	}

	const [auth, setAuthState] = useState({ ...initState });

	const loginSuccess = (result) => { // handles success responses from the loginForm component
		setAuthState({
			...auth,
			user: result.user.name,
			email: result.user.email,
			cdmApiToken: result.success.token,
			message: "Logging activity of: " + result.user.name,
		});
	};

	const loginFailure = (error) => {// handles success===undefined responses from the loginForm component
		setAuthState({
			...auth,
			user: '',
			email: '',
			cdmApiToken: '',
			message: error,
		});
	};

	useEffect(() => { saveState(auth); }, [auth]);

	return auth.cdmApiToken === '' ?
		(
			<Router>
				<div className="App">
					<Header {...auth} />
					<LoginForm {...auth} loginSuccess={loginSuccess} loginFailure={loginFailure} />
					<Footer />
				</div>
			</Router>
		) : (

			<Router>
				<div className="App">
					<Header {...auth} />
					<Route exact path="/" render={(props) => <Home {...props} />} />
					<Route path="/profile" render={(props) => <UserProfile {...props} {...auth} />} />
					<Route exact path="/events" render={(props) => <Events {...props} {...auth}/>} />
					<Route exact path="/calendar" render={(props) => <EventCalendar {...props} /> } />
					<Route exact path="/map" render={(props) => <EventMap {...props} /> }/>
					<Route exact path="/brands" render={(props) => <Brands {...props} />} />
					<Route exact path="/venues" render={(props) => <Venues {...props} />} />
					<Route exact path="/logout" render={(props => <Logout {...props} {...auth} loginSuccess={loginSuccess} loginFailure={loginFailure} />)} />
					<Footer />
				</div>
			</Router>
		);
};

export default App;