import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import { Redirect } from "react-router-dom";
import URL from './config'
import './LoginForm.css';

const LoginForm = ({ loginSuccess, loginFailure, ...auth }) => {

	const onSubmitLogin = e => {
		e.preventDefault();
		let formData = new FormData();
		formData.append('email', form.email);
		formData.append('password', form.password);

		fetch(URL.API + 'login', {
			method: 'post',
			body: formData
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			if (data.success !== undefined) {
				loginSuccess(data);

			}
			else { loginFailure(data.error); }
		});
	};

	const updateField = e => {
		setValues({ ...form, [e.target.name]: e.target.value });
	};

	const [form, setValues] = useState({ email: '', password: '' });


	if (auth.cdmApiToken === '') {
		return (

			<div className="login-wrapper" >
				<form className="login-form" onSubmit={onSubmitLogin}>
					<span className="input-row">
						<label>Email: </label>
						<input type="text" name="email" value={form.email} onChange={updateField} />
					</span>
					<br />
					<span className="input-row">
						<label>Password: </label>
						<input type="password" name="password" value={form.password} onChange={updateField} /></span>
					<br />
					<span className="input-row">
						<Button type="submit" variant="primary" id="cdmButton" size="md"  >Login</Button>
							</span>
				</form>

				<p>{auth.message}</p>
			</div>


		);
	} else {
		return (
			<Redirect to="/" />
		);
	};

};

export default LoginForm;