import React, { useState, useEffect } from "react"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import axios from "axios"
import './Brands.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'


const Brands = () => {

	function logoFormatter(cell, row) {
		return (
			<OverlayTrigger key={`flub-${row['id']}`} placement='right' overlay={
				<Tooltip id={`tooltip-${row['id']}`} styleName="logo-tooltip" >
					<img src={cell} alt="" width="200"></img>
				</Tooltip>
			}>
				<img src={cell} alt="" width="64"></img>
			</OverlayTrigger>
		)
	}

	const [brandData, setBrandData] = useState([])

	useEffect(() => {
		const fetchData = async () => {
			const result = await axios('event_brands');
			setBrandData(result.data.data);
		}
		fetchData();
	}, [])

	let brandDataLogos = brandData.map((brand, data) => {
		if (!brand['logo_lbg_png_200']) {
			brand['logo'] = "no_logo.svg";
		} else {
			brand['logo'] = brand['logo_path'] + brand['logo_lbg_png_200']
		}
		return brand;
	})

	const defaultSorted = [{
		dataField: 'name',
		order: 'asc'
	}]

	const columns = [{
			dataField: 'id',
			text: 'ID',
			headerStyle: (colum, colIndex) => {
				return { width: '3em', textAlign: 'center' }
			}
		}, {
			dataField: 'name',
			text: 'Name',
			filter: textFilter()
		}, {
			dataField: 'url_default',
			text: 'Website',
			filter: textFilter()
		}, {
			dataField: 'logo',
			text: 'Logo',
			formatter: logoFormatter
		}
	]

	return (
		<BootstrapTable keyField={'id'}
			data={brandDataLogos}
			columns={columns}
			pagination={paginationFactory()}
			filter={filterFactory()}
			bootstrap4
			striped
			defaultSorted={defaultSorted}
		/>
	)
}

export default Brands