import React, { useState, useEffect } from "react";
import axios from "axios";
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";


const localizer = momentLocalizer(moment);

const EventCalendar = (props) => {

	const [eventData, setEventData] = useState([]);

	useEffect(() => {
			const fetchData = async () => {
					const result = await axios(
							'listEvents'
					);
					const eventRes=result.data.data;
					console.dir(eventRes);
					let events = eventRes.map( (event) => {
							return (
								{
									'title': event.id+": "+event.display_name ,
									'allDay': true,
									'start': new Date(event.datetime_start),
									'end': new Date(event.datetime_end)
								}
							);
					});
					console.log(events);
					setEventData(events);
			}
			fetchData();
	}, []);

	console.log(eventData);

	return(
		<div className="cal-wrap">
		<div>
			<Calendar
				defaultDate={new Date()}
				defaultView="month"
				events={eventData}
				localizer={localizer}
				resizable
				style={{ height: "80vh" }}
			/>
		</div>
		</div>
		

	);
}

/*
class EventCalendar extends Component {
  state = {
    events: [
      {
        start: new Date(),
        end: new Date(moment().add(1, "days")),
        title: "Some title"
      }
    ]
  };


  onEventResize = (type, { event, start, end, allDay }) => {
    this.setState(state => {
      state.events[0].start = start;
      state.events[0].end = end;
      return { events: state.events };
    });
  };

  onEventDrop = ({ event, start, end, allDay }) => {
    console.log(start);
  };
*/
export default EventCalendar;