import React from "react";
import { Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Logo from "./logo.svg";

const Header = ({ ...auth }) => {
  if (auth.cdmApiToken === "") {
    return (
      <Navbar collapseOnSelect className="App-header">
        <Navbar.Brand>
          <LinkContainer to="/">
            <img src={Logo} alt="CDM Media logo" className="App-logo" />
          </LinkContainer>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      </Navbar>
    );
  } else {
    return (
      <Navbar collapseOnSelect expand="md" className="App-header">
        <Navbar.Brand>
          <LinkContainer to="/">
            <img src={Logo} alt="CDM Media logo" className="App-logo" />
          </LinkContainer>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title="Events" id="user-menu">
              <LinkContainer to="events">
                <NavDropdown.Item>Data View</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="map">
                <NavDropdown.Item>Map</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="calendar">
                <NavDropdown.Item>Calendar</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="brands">
                <NavDropdown.Item>Brands</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to="venues">
              <Button>Venues</Button>
            </LinkContainer>
            {/*}
												<NavDropdown title="Onsite Tools" id="user-menu">
														<LinkContainer to="SelectEvent"><NavDropdown.Item >Select Event</NavDropdown.Item></LinkContainer>
														<LinkContainer to="Checkin"><NavDropdown.Item >Checkin</NavDropdown.Item></LinkContainer>
														<LinkContainer to="Meetings"><NavDropdown.Item >Meetings</NavDropdown.Item></LinkContainer>
												</NavDropdown>
												*/}
            <NavDropdown title={auth.user} id="user-menu">
              <LinkContainer to="Profile">
                <NavDropdown.Item>User Profile</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="Account">
                <NavDropdown.Item>Company Account</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="Logout">
                <NavDropdown.Item>Log Out</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
};

export default Header;
