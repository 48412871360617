import React, { useState, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import axios from "axios";
import URL from './config';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

const Venues = () => {

    const [venueData, setVenueData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                URL.API + 'venuesGeo'
            );
            console.log(result);
            setVenueData(result.data.data);
        }
        fetchData();
    }, []);

    const columns = [{
        dataField: 'id',
        text: 'ID',
        headerStyle: (colum, colIndex) => {
            return { width: '3em', textAlign: 'center' };
        }
    }, {
        dataField: 'name',
        text: 'Name',
        filter: textFilter()
    }, {
        dataField: 'city.name',
        text: 'City',
        filter: textFilter()
    }, {
        dataField: 'region.name',
        text: 'Region',
        filter: textFilter()
    }, {
        dataField: 'country.name',
        text: 'Country',
        filter: textFilter()

    }, {
        dataField: 'type',
        text: 'Type',
        filter: textFilter(),
        headerStyle: (colum, colIndex) => {
            return { width: '10em' };
        }
    }, {
        dataField: 'url',
        text: 'Website'
    }];



    return (
        <BootstrapTable keyField='id'
            data={venueData}
            columns={columns}
            pagination={paginationFactory()}
            filter={filterFactory()}
            bootstrap4
            striped
        />

    );

};

export default Venues;