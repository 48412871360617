import React from 'react';

const UserProfile = ({ ...auth }) => {
	return (
		<div className="user-profile">
			<h1>User Profile - {auth.user}</h1>
		</div>
	);
};

export default UserProfile;