import React from "react";

const Home = () => {

    return (
        <div className="Home">
            <h1>Homepage</h1>
        </div>
    );
};

export default Home;