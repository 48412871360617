import React from "react";
import LoginForm from "./LoginForm";

const Logout = ({ loginSuccess, loginFailure, ...auth }) => {

    loginFailure("Successfully Logged out");

    return (
        <LoginForm {...auth} loginSuccess={loginSuccess} loginFailure={loginFailure} />
    );

}

export default Logout;