import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import axios from "axios";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

const Events = ({...auth}) => {

  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("futureEvents");
      setEventData(result.data.data);
    };
    fetchData();
  }, []);

  console.log(eventData);

  const defaultSorted = [
    {
      dataField: "datetime_start",
      order: "asc"
    }
  ];

  const columns = [
    {
      dataField: "id",
      text: "ID",
      headerStyle: (colum, colIndex) => {
        return { width: "3em", textAlign: "center" };
      }
    },
    {
      dataField: "display_name",
      text: "Name",
      filter: textFilter()
    },
    {
      dataField: "dates_text",
      text: "Dates",
      filter: textFilter()
    },
    {
      dataField: "exec_type.title_abrv",
      text: "Community",
      filter: textFilter()
    },
    {
      dataField: "city.name",
      text: "City",
      filter: textFilter()
    },
    {
      dataField: "event_type.name",
      text: "Format",
      filter: textFilter()
    },
    {
      dataField: "datetime_start",
      text: "Start",
      sort: true,
      hidden: true
    },
    {
      dataField: "venue.name",
      text: "Venue",
      filter: textFilter()
    },
    {
      dataField: "unique_url",
      text: "Website",
      filter: textFilter()
    }
  ];

  return (
    <BootstrapTable
      keyField={"id"}
      data={eventData}
      columns={columns}
      pagination={paginationFactory({ sizePerPage: 15 })}
      filter={filterFactory()}
      bootstrap4
      striped
      defaultSorted={defaultSorted}
    />
  );
};

export default Events;
